export default [
  {
    header: 'Latest',
    title: 'Table',
    icon: 'GridIcon',
    route: 'dashboard-table',
    action: 'read',
    resource: 'table',
  },
  {
    header: 'Map',
    title: 'Map',
    icon: 'MapIcon',
    route: 'dashboard-map',
    action: 'read',
    resource: 'map',
  },
  {
    header: 'Reports',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Consumption',
        icon: 'BarChart2Icon',
        children: [
          {
            title: 'Consumer Type Wise ',
            route: 'reports-consumer-type-wise-consumption',
            action: 'read',
            resource: 'reports',
          },
          {
            title: 'Aquifer Wise',
            route: 'reports-aquifers-wise-consumption',
            action: 'read',
            resource: 'reports',
          },
          {
            title: 'Meter Size Wise',
            route: 'reports-meter-size-wise-consumption',
            action: 'read',
            resource: 'reports',
          },
          {
            title: 'Water Source Wise',
            route: 'reports-water-source-wise-consumption',
            action: 'read',
            resource: 'reports',
          },
          {
            title: 'Area Wise',
            route: 'reports-area-wise-consumption',
            action: 'read',
            resource: 'reports',
          }
        ]
      },
      {
        title: 'Consumer List',
        icon: 'BarChart2Icon',
        route: 'reports-consumer-list',
        action: 'read',
        resource: 'reports',
      },
      {
        title: 'Individual Consumption',
        icon: 'BarChart2Icon',
        route: 'reports-single-consumer-consumption',
        action: 'read',
        resource: 'reports',
      },
    ],
  },
  {
    header: 'Meters',
    title: 'Meters',
    icon: 'GitPullRequestIcon',
    route: 'meters-list',
    action: 'read',
    resource: 'meters',
  },

  {
    header: 'Billing',
    icon: 'TrelloIcon',
    children: [
      {
        title: 'Bills',
        icon: 'SidebarIcon',
        route: 'billing-invoices',
        action: 'read',
        resource: 'billing',
      },
      {
        title: 'Generate Bills',
        icon: 'PlusIcon',
        route: 'billing-generate',
        action: 'create',
        resource: 'billing',
      },
      {
        title: 'Settings',
        icon: 'SettingsIcon',
        route: 'billing-settings',
        action: 'read',
        resource: 'billing-settings',
      },
    ],
  },

  {
    header: 'Other',
    icon: 'MoreHorizontalIcon',
    children: [
      {
        title: 'TV Screen',
        route: 'dashboard-tv-screen',
        icon: 'TvIcon',
        action: 'read',
        resource: 'tv-screen',
      },
      {
        title: 'Users',
        route: 'users-list',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'users',
      },
      {
        title: 'Activity Log',
        route: 'logs-list',
        icon: 'LayersIcon',
        action: 'read',
        resource: 'logs',
      },
      {
        title: 'User Manual',
        href: 'https://momaa.live/docs/user-manual.pdf',
        icon: 'BookIcon',
        action: 'read',
        resource: 'default',
      },
      {
        title: 'Logout',
        route: 'logout',
        icon: 'LogOutIcon',
        action: 'read',
        resource: 'default',
      },
    ],
  },
]
